<template>
  <div style="display: flex;justify-content: center;align-items: center;padding: 24px;height: 100%">
    <div style="width: 40%;height: 100%;">

      <div style="display: flex;justify-content: space-between">
        <label class="cssca"
               style="margin-bottom: 20px;width: 150px;text-align:  center;">
          上传图片
          <input type="file" style="display: none"
                 @change="toBase64"
                 accept="image/*"/>
        </label>


        <label class="cssca" v-if="preZip"
               style="margin-bottom: 20px;width: 150px;text-align:  center;" @click="download()">
          下载图片

        </label>
      </div>


      <div style="margin-top: 25px">
        <b style="font-size: 16px;font-weight: 600">
          图片压缩质量:
        </b>
        <a-slider :min="0" :step="1" :max="100" v-model="zipLevel"/>
      </div>


    </div>

    <div
        style="width: 60%;height: 100%;background-position: center;background-size: contain;background-repeat: no-repeat"
        :style="{backgroundImage:`url(${preZip})`}">
    </div>

  </div>
</template>


<script>
const key = '123'
export default {
  name: "imgCompress",
  data() {
    return {
      preZip: '',
      zipLevel: 85,
      success: '',
    }
  },
  methods: {
    toBase64(e) {
      this.$message.loading({content: '上传中', key, duration: 0})
      let file = e.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.$message.success({content: '已上传', key, duration: 2})
        this.preZip = reader.result

      }
    },


    async download() {
      this.$message.loading({content: '压缩中', key, duration: 0})
      let file = document.querySelector('input[type=file]').files[0] // 获取选择的文件，这里是图片类型
      let reader = new FileReader()
      await reader.readAsDataURL(file) //读取文件并将文件以URL的形式保存在resulr属性中 base64格式
      reader.onload = async (e) => { // 文件读取完成时触发
        let result = e.target.result // base64格式图片地址
        const image = new Image();
        image.src = result // 设置image的地址为base64的地址
        image.onload = async () => { // 图片加载完成后才能进行压缩处理，从而转换为base64 进行赋值
          this.$message.loading({content: '压缩完成', key, duration: 0})
          let width = image.width // 图片宽度
          let height = image.height
          let dataUrl = await this.compress(image, width, height, this.zipLevel / 100)
          this.success = dataUrl
          await this.startDownload()
        }
      }
    },


    async startDownload() {
      this.$message.loading({content: '等待下载', key, duration: 0})
      const link = await this.$base64ToBlob(this.success)
      console.log(link)
      setTimeout(() => {
        this.$message.success({content: '已下载', key, duration: 1})

        const date = new Date()
        const year = date.getFullYear()
        const month = date.getMonth() - 1
        const day = date.getDay()
        const hour = date.getHours()
        const min = date.getMinutes()
        const sec = date.getSeconds()


        const a = document.createElement('a')
        a.href = URL.createObjectURL(link)
        a.download = `www.yskam.com--音速电玩___${year}年${month}月${day}日---${hour}时${min}分${sec}秒.png`
        a.click()
      }, 1500)

    },

    /*
     * 图片压缩
     * img   原始图片
     * width  压缩后的宽度
     * height  压缩后的高度
     * ratio  压缩比率
      */
    compress(img, width, height, ratio) {
      let canvas, ctx, img64;
      canvas = document.createElement('canvas')
      canvas.width = width;
      canvas.height = height;
      ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      img64 = canvas.toDataURL("image/jpeg", ratio);
      return img64;
    }
  }
}
</script>

<style scoped>


.cssca {
  border: 0;
  border-radius: 10px;
  background: #2ec4b6;
  /* 文字变大写 */
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 15px 30px;
  outline: none;
  position: relative;
  /* 添加动画，对元素的 border-radius 添加 3s 的延迟效果 */
  transition: all 3s;
  -webkit-transition: border-radius 3s;
}

.cssca:hover {
  border-radius: 50px 10px 50px 10px;
  background-color: red;
}


label {
  display: block;
  margin-top: 20px;
}

input[type="text"], textarea {
  display: block;
  width: 100%;
  padding: 8px;
}

input[type="checkbox"] {

  width: 20px;
  margin-right: 50px;
  margin-top: 20px;
}

textarea {
  height: 200px;
}

#checkboxes label {
  display: inline-block;
  margin-top: 0;
}

#checkboxes input {
  display: inline-block;
  margin-left: 10px;
}


</style>
